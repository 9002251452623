import PageContent from "components/page/PageContent";
import PageHeader from "components/page/PageHeader";
import React from "react";
import Helmet from "react-helmet";

import html from "../assets/warranty.html";
import GlobalLayout from "../components/page/GlobalLayout";

export default function Warranty() {
  return (
    <GlobalLayout>
      <Helmet>
        <title>Rhombus Industry-Leading Security Camera System Warranty</title>
        <meta
          name="description"
          content="Industry-leading enterprise video surveillance warranty. Experience higher-quality security products with unbeatable peace of mind."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div>
        <PageContent>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </PageContent>
      </div>
    </GlobalLayout>
  );
}
