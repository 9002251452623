import { css, cx } from "linaria";
import PropTypes from "prop-types";
import React from "react";

import PageContent from "./PageContent";

const rootClass = css`
  background-image: linear-gradient(to right, var(--blue-500), var(--teal-500));
`;
const textClass = css`
  padding: 30px 0;
  color: white;
  @media (max-width: 500px) {
    & {
      padding: 20px 0;
    }
  }
`;

const textWithDescriptionClass = cx(
  textClass,
  css`
    padding: 18px 0;
  `
);

const titleClass = css`
  color: white;
  font-weight: 300;
  font-size: 30px;
  @media (max-width: 500px) {
    & {
      font-size: 20px;
    }
  }
`;

const titleWithDescriptionClass = cx(
  titleClass,
  css`
    font-size: 24px;
  `
);

const subtitleClass = css`
  color: white;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
`;
const descriptionClass = css`
  color: white;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  max-width: 480px;
  width: 100%;
  margin: 10px auto 0 auto;
`;

class PageHeader extends React.Component {
  render() {
    return (
      <div
        className={rootClass}
        style={{
          backgroundImage: this.props.backgroundImage,
        }}
      >
        <PageContent>
          <div
            className={
              this.props.description ? textWithDescriptionClass : textClass
            }
            style={{
              textAlign: this.props.center ? "center" : null,
            }}
          >
            <div
              className={
                this.props.description ? titleWithDescriptionClass : titleClass
              }
              dangerouslySetInnerHTML={{ __html: this.props.title }}
            />
            {this.props.subtitle && (
              <div className={subtitleClass}>{this.props.subtitle}</div>
            )}
            {this.props.description && (
              <div className={descriptionClass}>{this.props.description}</div>
            )}
          </div>
        </PageContent>
      </div>
    );
  }
}
PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  backgroundImage: PropTypes.string,
  center: PropTypes.bool,
};
PageHeader.defaultProps = {
  subtitle: null,
  description: null,
  backgroundImage: null,
  center: false,
};
export default PageHeader;
